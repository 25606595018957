/* eslint-disable camelcase */
<template>
<div id="query_robot">
    <div  class="robot-management">
      <strong style="font-size:13px">机器人管理</strong>
    <div class="buttons">
      <el-input
      v-model="robot_info"
      placeholder="请输入机器人名称"
      style="width: 16vw;"
      clearable
    >
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
        <el-select v-model.trim="MissionTemplateForm" style="width: 16vw;"
         placeholder="请选择机器人状态" clearable>
          <el-option
            v-for="state in StatusOptions"
            :key="state.key"
            :value="state.key"
            :label="state.display_name"
          ></el-option>
        </el-select>
      <el-button @click="querySearchAsync()" type="primary" icon="el-icon-search" size="mini">查询</el-button>
      <el-button @click="tableRowClassName1()" size="mini" type="primary" >重置</el-button>
      <el-button type="primary"  @click="tableRowClassName()" size="mini">新增机器人</el-button>
    </div>
    </div>
    <robotadd
    :Visible="dialogTableVisible"
    :robottype="robottype"
    @cancel="visible_false"
    >
    </robotadd>
    <div class="table-container">
    <el-table :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)" :stripe=true :highlight-current-row=true   :row-style="{height: '0.1'}" :cell-style="{padding: '4px'}" >
      <el-table-column prop="id" label="机器人ID" align="center" ></el-table-column>
      <el-table-column prop="robotname" label="机器人名称" align="center"></el-table-column>
      <el-table-column prop="describe" label="机器人描述" align="center"></el-table-column>
      <el-table-column prop="typename" label="机器人类别" align="center" ></el-table-column>
      <el-table-column prop="createdby" label="创建人" align="center"></el-table-column>
      <el-table-column prop="createdon" label="创建时间" width="180px" align="center"></el-table-column>

      <el-table-column label="状态" align="center" >
        <template slot-scope="scope">
          <el-tag type="success"  v-if="scope.row.active==1">启用</el-tag>
          <el-tag type="danger"  v-if="scope.row.active==0">停用</el-tag>
          </template>
      </el-table-column>
      <el-table-column prop="robot_type" label="操作" align="center">
        <template slot-scope="scope">
          <el-button  v-if="scope.row.active==1" @click="switchChange(scope.row.id)" type="text" round>停用</el-button>
          <el-button v-if="scope.row.active==0" @click="switchChange(scope.row.id)" type="text" round>启用</el-button>
        </template>
      </el-table-column>
    </el-table>
    </div>
  <div style="text-align: right; padding: 30px">
  <el-pagination
    @current-change="handleCurrentChange"
    layout="prev, pager, next, jumper"
    :total="tableData.length"
    :page-size="pageSize"
    background
  >
</el-pagination>
</div>
</div>
</template>

<script>

import robotadd from './robotadd.vue'
import { Message } from 'element-ui'
export default {
  name: 'query_robot',
  components: {
    robotadd
  },
  data () {
    return {
      tableData: [],
      dialogTableVisible: false,
      total: 0,
      currentPage: 1,
      pageSize: 10,
      robot_info: '',
      MissionTemplateForm: '',
      StatusOptions: [
        { key: 1, display_name: '启用' },
        { key: 0, display_name: '停用' }
      ],
      robottype: []
    }
  },
  created () {
    this.getdata()
  },
  methods: {
    typeget () {
      this.axios.get('/Robot_Management/get_robottype/', {
      }).then((res) => {
        this.robottype = res.data.data
      }).catch((error) => {
        console.log(error)
      })
    },
    getdata () {
      this.axios.get('/Robot_Management/robot/', {
      }).then((res) => {
        console.log(res.data.data)
        console.log(res.data)
        this.tableData = res.data.data
        this.total = this.tableData.length
      }).catch((error) => {
        console.log(error)
      })
    },
    handleCurrentChange (val) {
      this.currentPage = val
    },
    tableRowClassName () {
      this.typeget()
      this.dialogTableVisible = true
    },
    visible_false (ev) {
      this.dialogTableVisible = ev
      this.getdata()
    },
    // 重置按钮
    tableRowClassName1 () {
      this.MissionTemplateForm = ''
      this.robot_info = ''
      this.axios.get('/Robot_Management/robot/', {
      }).then((res) => {
        this.tableData = res.data.data
        this.total = this.tableData.length
        console.log(res.data.data)
      })
    },
    // 传入需要操作机器人状态的ID
    switchChange (data) {
      console.log(data)
      this.istag = !this.istag
      this.axios.post('/Robot_Management/robot_state/', {
        data
      }).then((res) => {
        if (res.data.res === true) {
          this.tableData = res.data.data
          Message.success(res.data.message)
          this.getdata()
        } else if (res.data.res === false) {
          Message.warning(res.data.message)
        }
      })
    },
    current_change: function (currentPage) {
      this.currentPage = currentPage
    },
    querySearchAsync () {
      const robotinfo = this.robot_info
      const MissionTemplateForm = this.MissionTemplateForm
      console.log(this.MissionTemplateForm, this.robot_info)
      if (robotinfo !== '' || MissionTemplateForm !== '') {
        this.axios.post('/Robot_Management/robotserch_info/', {
          robotinfo, MissionTemplateForm
        }).then((res) => {
          this.tableData = res.data.data
          if (res.data.message === '查询失败！') {
            this.tableData = []
          }
          console.log(res.data.message)
        })
      } else {
        this.getdata()
      }
    }
  }
}
</script>

<style scoped>
.el-table .warning-row {
  background: oldlace;
}

.el-table >>> .success-row {
  background: #f0f9eb;
}
.el-divider{
   margin: 1px;
}
#active{
  background:red;
}
#active1{
  background:green ;
}

.table-container {
  display: flex;
  flex-direction: column;
  height: 100;
  position: relative;
}
.robot-management {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}
.robot-management strong {
  font-size: 2vw;
}
.buttons {
  display: flex;
  gap: 10px;
}
</style>
